<template>
  <div>
    <img class="img__body" :src="imgBody" alt="" />
    <div class="container__logo">
      <img class="logo__clear" :src="imgLogo" alt="" />
    </div>
    <div class="container_formulario__registro">
      <form class="form" @submit.prevent="handleSubmit">
        <div class="instructions">
          <p>
            Tus respuestas han sido enviadas. <br />
            gracias por responder.
          </p>
          <sweetalert-icon icon="success" id="success-sa" />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import SweetalertIcon from "vue-sweetalert-icons";
import { mapGetters } from "vuex";
export default {
  components: { SweetalertIcon },
  data() {
    return {
      imgBody: require("@/assets/img/clear people sin letras.png"),
      imgLogo: require("@/assets/img/clear people naranja (1).png"),
    };
  },
  methods: {
    handleSubmit() {
      this.$router.push("/quiz");
    },
  },
  computed: {
    ...mapGetters("Quiz", {
      quiz: "getQuiz",
    }),
  },
};
</script>

<style scoped>
/* inicio - resets del css creado por ifrain */
p {
  position: unset;
}

.form {
  height: auto;
}

.btn__comenzar {
  position: unset;
}

/* fin - resets del css creado por ifrain */

/* inicio - css componente */

.instructions {
  width: 80%;
  margin: 0 auto;
  padding: 20px 0;
  text-align: center;
}

.instructions h2 {
  color: #5a5a5a;
  font-weight: 400;
}

.instructions p {
  padding-top: 20px;
  text-align: center;
  color: #676767;
  font-weight: 300;
}

#success-sa {
  transform: scale(1.3);
}

/* fin - css componente */
</style>
